<style>
	.v-text-field input {
	  font-size: 0.75em;
	}
  </style>
<template>
	<div>
		<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>
					<v-icon class='mb-1'>mdi-account-key</v-icon>
					ACCESOS FINCAS
				</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-spacer></v-spacer>
				<v-text-field v-model="busqueda" @click:append="cargarLista()" @keyup.enter="cargarLista()"
					append-icon="mdi-magnify" label="Buscar" single-line class='mt-5'>
				</v-text-field>
				<v-spacer></v-spacer>
                <v-btn 
							small
							color = 'primary'
							@click="exportarLista()"
						> 
							<v-icon>mdi-file-excel </v-icon> Exportar
						</v-btn> 
			</v-toolbar>
			<v-data-table :headers="headers" :items="lista" :items-per-page=itemsPerPage 
                :options.sync="options"
				hide-default-footer :loading=loadingTable class="elevation-1"
                :server-items-length="totalItems"
                >
				

				<template v-slot:footer>
					<v-pagination class='mt-10' v-model="currentPage" :length="pageCount" @input="handlePageChange"
						total-visible=10></v-pagination>
				</template>
			</v-data-table>
		</v-card>




	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

export default {
	name: "UsuariosFincasComponent",
	props: {
	 

	},
	components: {

	},
	data: () => ({
		headers: [
			
			{ text: 'Nombre', value: 'ENTE_NOMBRE', sortable:true },
			{ text: 'Usuario', value: 'USER_NAME' , sortable:true},
            { text: 'Email', value: 'ENTE_EMAIL1' ,sortable:true},
            { text: 'Pagado Desde', value: 'FECHA_INICIO' },
            { text: 'Pagado Hasta', value: 'FECHA_FIN' , sortable:true},
            

		],


		lista: [],
		show: false,
		show2: false,
		options: {},
		notifications: false,
		dialogUsuario: false,
		formTitulo: "Cambiar contraseña",
		duser: {
			userName : "",
			password : "",
			password2 : ""
			},

		busqueda: null,

		itemsPerPage: 20,
		pageCount: 1,
		totalItems: 0,
		currentPage: 1,
         



	}),
	computed: {

		...mapState('master', ['loadingTable', 'user', 'months']),

		...mapGetters('access', [])
		,
		
		 
		reglasPass() {
			
			const strongPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.!@#\$%\^&\*])(?=.{5,})");
                
			
			const mediumPpattern = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
			
			if(strongPattern.test(this.duser.password) == false) {
				return [false || "La contraseña debe tener al menos 6 caractéres, al menos una letra mayúscula un número y un caractér especial"]				
			}

			/*if(this.duser.password.length < 5 ) {
				return [false || "La contraseña debe tener al menos 5 caractéres"]				
			}*/

			if (this.duser.password  != this.duser.password2) {				
					return [false || "Las contraseñas no coinciden"]				
			}

		},

	},
	methods: {
		...mapMutations('master', ['setUrl', 'setOverlay', 'setMenu', 'setLoadingTable', 'setTitleToolbar']),
		...mapActions('master', ['requestApi', 'alertNotification']),

		cargarLista() {
			this.setLoadingTable(true)
			this.setUrl('api/lista-accesos-fincas')

			this.requestApi({
				method: 'GET',
				data: {
					"pageCount": this.pageCount,
					"itemsPerPage": this.itemsPerPage,
					"page": this.currentPage,
					"busqueda": this.busqueda,
                    "sortBy": this.options.sortBy[0],
                    "sortDesc": this.options.sortDesc[0]
				}
			}).then(res => {
				//console.log(res);			        			    		
				this.lista = res.data.lista;
				//this.paises = res.data.paises;
				this.pageCount = res.data.pageCount;
				this.totalItems = res.data.totalItems
                
				//this.tipoEmpresa = res.data.tipoEmpresa
				//console.log(this.totalItems)
			}).catch(() => {

			}).then(() => {
				this.setLoadingTable(false)
			})

		},

		

		abrirFormUsuario(du) {
			
			this.duser.nombres = du.ENTE_NOMBRE
			this.duser.userName  = du.USER_NAME;
			this.duser.password = "";
			this.duser.password2 = "";
			this.dialogUsuario= true
			//this.daeEdit = false;

				//this.daeEdit = true;

		},

		exportarLista() {
			this.setUrl('api/exportar-accesos-fincas')
        	 this.requestApi({
                 method: 'GET',
                 data :{
					"pageCount": this.pageCount,
					"itemsPerPage": this.itemsPerPage,
					"page": this.currentPage,
					"busqueda": this.busqueda,
                    "sortBy": this.options.sortBy[0],
                    "sortDesc": this.options.sortDesc[0]                     
                 }
             }).then(res =>{
              let a = document.createElement("a");
			  a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data.datos;
              a.download = res.data.archivo;

              a.click();
            }).then(()=>{
               
            })
		},
		

		handlePageChange() {
			this.cargarLista()
		},
	},
	mounted() {
		//this.$vuetify.lang.current = 'es'
		//this.cargarLista()
		document.title = "Sistema de carga"
		this.setTitleToolbar('Accesos a usuarios de fincas')
	},
	watch: {
		options: {
			handler() {
				this.cargarLista()

			},
			deep: true,
		},
	},

}

</script>